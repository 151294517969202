<template>
    <div class="d-flex flex-column charging-wrapper">
        <div v-if="selectedPin" class="mt-4 mb-2 d-flex flex-column align-center pin-info">
            <span class="text-h6">{{$t("chargingWelcomeTitle", {title: selectedPin.metadata.title})}}</span>
            <span class="text-caption" v-if="formattedAddress != null">{{formattedAddress}}</span>
            <div class="my-1 d-flex align-center justify-center chargers-wrapper">
                <!-- <div v-for="plug in availablePlugs" :key="`plug-${plug.id}`" :class="{'mx-4 px-5 py-1 d-flex flex-column rounded-xl charger-wrapper': true ,'disabled': plug.id == bookedPins[0].selectedPlug }">
                    <span class="text-caption">{{parseInt(plug.power)}}kW / {{plug.title}}</span>
                    <span class="text-caption text-center">{{selectedPin.metadata.pricePerMinutes * plug.priceFactor}}€/min</span>
                </div> -->
                <div :key="`plug-${availablePlugs[0].id}`" >
                    <span class="text-caption">{{parseInt(availablePlugs[0].power)}}kW - {{availablePlugs[0].title}} - {{selectedPin.metadata.pricePerMinutes * availablePlugs[0].priceFactor}}€/min</span>
                </div>
            </div>
        </div>
        <p v-if="bookedPins && bookedPins.length > 0" class="ma-0 d-flex align-baseline primary--text text-center text-subtitle-1 font-weight-bold" v-html="$t('chargingPageSlotGeneral')"></p>
        <a v-if="bookedPins && bookedPins.length > 0" class="text-center" href=""> {{ $t("chargingPageSlotLink") }}</a>
        <div class="mt-3 d-flex flex-column align-center charging">
            <span v-if="charging" class="mt-3 text-h5">{{$t("chargingOngoingTitle")}}</span>
            <span v-else-if="!charging && chargeValue > 10" class="mt-3 text-h5">{{$t("chargingOnPauseTitle")}}</span>
            <span v-else class="mt-3 text-h5">{{$t("chargingNotStartedTitle")}}</span>
            <v-progress-circular :rotate="90" :size="150" :width="8" :value="chargeValue" class="my-2" color="primary" >
                <div class="black--text d-flex flex-column align-center charging-info">
                    <span class="text-h5">{{chargeValue}}%</span>
                    <span class="text-subtitle-1">{{qtyByProductId(57421)}} kWh</span>
                    <span class="text-subtitle-1">{{formatNumber(kmCharged)}} km</span>
                    <span class="text-subtitle-1">{{formatPrice(totalAmount)}} €</span>
                </div>
            </v-progress-circular>
            <template v-if="charging == false && chargeValue == 10">
                <v-btn color="primary" @click="charging = true; startCharge()"> {{$t("chargingStartChargeLabel")}} </v-btn>
            </template>
            <template v-else>
                <div class="my-4 black--text d-flex flex-column align-center charging-info">
                    <span class="text-subtitle-1">{{$t("chargingChargeFromLabel", {time: formatTimeFromSeconds(time)})}}</span>
                    <span v-if="!(finishItermediateState || finished)" class="text-subtitle-1">{{$t("chargingCompletlyChargedInLabel", {remainingTime : formatTimeFromSeconds(remainingTime)})}}</span>
                    <span v-if="!(finishItermediateState || finished)" class="text-subtitle-1">{{$t("chargingPowerLabel", {power: parseInt(selectedPlug.power)})}}</span>
                </div>
                <div v-if="enablePenalties && (finishItermediateState || finished)" class="ma-4 red--text text--darken-2 text-center">{{$t("chargingPenaltiesWarnLabel")}} </div>
                <v-btn v-if="!finishItermediateState" color="primary" @click="pauseCharge()"> {{$t("chargingStopChargeLabel")}} </v-btn>
                <v-btn v-if="showStartShop && !finishItermediateState" class="my-3" color="primary" @click="goToStore()"> {{$t("chargingStartShoppingLabel")}} </v-btn>
                <div v-if="finishItermediateState && ! finished" class="d-flex align-center my-2">
                    <v-btn color="primary" class="mx-1" small @click="finishItermediateState = false; finished= false; startCharge(true)">{{$t("chargingContinueChargeLabel")}} </v-btn>
                    <v-btn color="primary" class="mx-1" small @click="handleFinishCharge()"> {{$t("chargingFinishChargeLabel")}} </v-btn>
                </div>
                <v-btn v-if="finished" color="primary" @click="handleSeeTicket()"> {{$t("chargingShowTicketLabel")}} </v-btn>
            </template>
        </div>

        <div v-if="charging == false && chargeValue == 10" class="mt-10">
            <template>
                <v-card
                    elevation="24"
                    max-width="400"
                    class="mx-auto"
                >
                    <v-carousel
                        :show-arrows="false"
                        hide-delimiters
                        cycle
                        hide-delimiter-background
                        height="auto"
                    >
                        <v-carousel-item v-for="(picture,i) in pictures" :key="i" :src="picture.src" :href="picture.href" target="_blank" >   
                        </v-carousel-item>
                    </v-carousel>
                </v-card>
            </template>
        </div>

    </div>
</template>

<script>
    import {ProductService} from "@/services/product.service"

    import { mapGetters, mapActions } from "vuex"
    export default {
        name: "ChargeComponent",
        props: {
            maxValue: {
                type: Number
            },
            maxTime: {
                type: Number
            },
            withMQTT: {
                type: Boolean,
                default: true
            },
            discount: {
                type: Boolean,
                default: false
            },
            showStartShop: {
                type: Boolean,
                default: false
            },
            enablePenalties: {
                type: Boolean,
                default: false
            },
            startChargeDirectly:{
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
                map: null,
                charging: false,
                chargeValue: 10,
                chargeInkW: 0,
                kmCharged: 0,
                chargeAmount: 0,
                finishItermediateState: false,
                finished: false,
                chargingInterval: null,
                time: 0,
                remainingTime: 5400,
                totalCapacity: 55,
                forceComplete: false,
                pictures: [
                    {
                        src: 'https://image.freepik.com/vecteurs-libre/modele-banniere-sandwich-au-poivre-noir-pour-restaurant-restauration-rapide_122059-327.jpg',
                        href: null
                    },
                    {
                        src: 'https://img.freepik.com/vecteurs-libre/scan-code-qr-pour-payer-banniere-vecteur-isometrique-salaire-qr-3d-paiement-sans-contact-sans-numeraire-paiement-electronique_254538-313.jpg?size=626&ext=jpg',
                        href: 'https://wl-dsp-scan-and-pay.pubqlf.co.as8677.net/'
                    },
                ],
            }
        },
        mounted(){
            this.emptyBasket()
            if(this.startCharging){
                this.startCharge()
            }
        },
        methods:{
            ...mapActions({
                addProductToBasket: "basket/addProduct",
                validBasket: "basket/validBasket",
                emptyBasket: "basket/emptyBasket",
                updateBookedPins: "bookingDialog/updateBookedPins",
                updateStartCharging: "charge/updateStartCharging",
            }),
            async startCharge(forceComplete){
                try{
                    let { data } = await ProductService.getProductById(57421)
                    this.charging = true
                    this.forceComplete = forceComplete
                    this.chargingInterval = setInterval(() => {
                        if(this.chargeValue < 100 || (this.maxTime && (this.time / 60) < this.maxTime || forceComplete)){
                            this.addProductToBasket(data)
                            this.chargeValue += 1
                            this.chargeInkW = (this.totalCapacity / 100) * this.chargeValue
                            this.time += 60
                            this.remainingTime -= 60
                            this.kmCharged += 2.75
                            this.chargeAmount += this.chargeInkW * 0.1
                        }
                        if(this.maxTime && (this.time / 60) >= this.maxTime && !forceComplete){
                            this.pauseCharge()
                        }
                        if(this.maxValue && this.chargeValue >= this.maxValue ){
                            this.pauseCharge()
                        }
                        if(this.chargeValue >= 100){
                            this.stopCharge()
                        }
                    }, 1000)
                }catch(e){
                    console.log(e)
                }
            },
            stopCharge(){
                clearInterval(this.chargingInterval)
                this.charging = false
                this.finishItermediateState = true
                this.finished = true
            },
            pauseCharge(){
                clearInterval(this.chargingInterval)
                this.charging = false
                this.finishItermediateState = true;
            },
            handleFinishCharge(){
                try{
                    if(this.withMQTT){
                            
                        let topic = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.OCULAR_ACTION
                        let message = {
                            action: "goto",
                            sequenceId: "3"
                        }
                        this.$mqtt.publishMqtt(topic, JSON.stringify(message), () => {
                            console.log("Sequence 3 started")
                            this.finished = true
                        } )

                    }else{
                        this.finished = true
                    }
                } catch (e) {
                    console.error(e)
                }
            },
            async handleSeeTicket(){
                try{
                    let comment = this.bookedPins[0] || this.selectedPin
                    comment.selectedPlug = this.selectedPlugId
                    comment.discount = this.discount
                    comment.penalties = this.enablePenalties
                    await this.validBasket({comment: JSON.stringify(comment)})
                    this.updateBookedPins([])
                    this.updateStartCharging(false)
                    this.$emit("finishCharge")
                    sessionStorage.removeItem("IEC_PAY_AND_DRIVE_SHOW_DISCOUNT")
                    this.$router.push({ name: 'Ticket' })
                } catch (e) {
                    console.error(e)
                }
            },
            goToStore(){
                this.$emit("finishCharge")
                this.$router.push({name: "Store"})
            }
        },
        computed:{
            ...mapGetters({
                selectedPin: "bookingDialog/selectedPin",
                bookedPins: "bookingDialog/bookedPins",
                paymentDone: "paymentDone",
                totalAmount: "basket/totalAmount",
                pinById: "pins/getPinById",
                qtyByProductId: "basket/qtyByProductId",
                startCharging: "charge/startCharging",
            }),
            formattedAddress(){
                if(this.selectedPin){
                    return this.selectedPin.metadata.address.split("<br>").join(" ")
                }
                return null
            },
            availablePlugs(){
                return [
                    {
                        id: 0,
                        title: "TYPE 2",
                        power: parseInt(this.selectedPin.metadata.power),
                        priceFactor: 1
                    },
                    {
                        id: 1,
                        title: "COMBO CCS",
                        power: parseInt(this.selectedPin.metadata.power) + 5,
                        priceFactor: 1.5
                    }
                ]
            },
            selectedPlug(){
                return this.availablePlugs.filter(plug => plug.id == this.selectedPlugId)[0]
            },
            selectedPlugId(){
                if(this.bookedPins[0]){
                    return this.bookedPins[0].selectedPlug
                }
                if(this.selectedPin){
                    return this.selectedPin.selectedPlug
                }
                return null
            }
        }
    }
</script>

<style scoped>
    .charger-wrapper {
        border: solid 1px #3c3c3c;
    }
    .charger-wrapper.disabled{
        background: #a1a1a1;
        color: white;
        border: solid 1px #3c3c3c;
    }
</style>