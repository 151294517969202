<template>
    <div>
        <v-dialog v-model="loader" persistent width="300">
            <v-card class="pt-3" color="primary" dark>
                <v-card-text>
                    {{$t(loaderText)}}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex"
    export default {
        methods: {
            ...mapActions(["UPDATE_LOADER", "resetLoaderText"])
        },
        computed: {
            ...mapGetters(["loader", "loaderText"])
        },
        watch:{
            loader(newVal){
                if(newVal = false){
                    this.resetLoaderText()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>