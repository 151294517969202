import {CustomerService} from "@/services/customer.service"
import Cookies from 'js-cookie'
let domain = "localhost"
if(process.env.NODE_ENV === 'production'){
    domain = ".pubqlf.co.as8677.net"
}
export default {
    namespaced: true,
    state: {
        customer: JSON.parse(localStorage.getItem("IEC_PAY_DRIVE_CUSTOMER")) || null
    },
    getters: {
        customer: state => {
            return state.customer;
        },
        customerToken: state => {
            if(!state.customer) return null
            return state.customer.token;
        },
        customerFirstName: state => {
            if(!state.customer) return null
            return state.customer.firstName;
        },
        customerLastName: state => {
            if(!state.customer) return null
            return state.customer.lastName;
        },
        customerEmail: state => {
            if(!state.customer) return null
            return state.customer.email;
        },
        customerComplexId: state => {
            if(!state.customer) return null
            return state.customer.complexId
        }
    },
    mutations: {
        updateCustomer(state, payload) {
            state.customer = payload;
            localStorage.setItem("IEC_PAY_DRIVE_CUSTOMER", JSON.stringify(state.customer))
            Cookies.set("IEC_FIRSTNAME", state.customer.firstName, {domain})
            Cookies.set("IEC_LASTNAME", state.customer.lastName, {domain})
            Cookies.set("IEC_EMAIL", state.customer.email, {domain})
            Cookies.set("IEC_TOKEN", state.customer.token, {domain})
            Cookies.set("IEC_COMPLEX_ID", state.customer.complexId, {domain})
        }
    },
    actions: {
        registerCustomerAnonym({commit}, payload) {
            return new Promise(async (resolve, reject) => {
                try{
                    let {data, status} = await CustomerService.registerCustomerAnonym(payload.firstName, payload.lastName, payload.email)
                    if(status == 200 && !data.error){
                        let customer = {
                            token: data.anonymToken,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            complexId: data.complexId,
                            email: payload.email
                        }
                        commit('updateCustomer', customer);
                        resolve()
                    }else{
                        reject()
                    }
                }catch(e){
                    console.error(e)
                    reject()
                }

            })
        },
        updateCustomerFromCookies({commit}){
            let customer = {
                token: Cookies.get("IEC_TOKEN"),
                firstName: Cookies.get("IEC_FIRSTNAME"),
                lastName: Cookies.get("IEC_LASTNAME"),
                complexId: Cookies.get("IEC_COMPLEX_ID"),
                email: Cookies.get("IEC_EMAIL")
            }
            commit('updateCustomer', customer);
        }
    }
};