import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import {pageInfoMixin} from "./mixins/pageInfoMixin"
import {utilsMixin} from "./mixins/utilsMixin"
import i18n from './i18n'
import store from './store'
import "animate.css"
import MQTT from "@/assets/js/Mqtt";
Vue.config.productionTip = false
Vue.mixin(pageInfoMixin)
Vue.mixin(utilsMixin)
Vue.prototype.$mqtt = MQTT

require('@/assets/css/custom.css');

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
});


new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
