<template>
    <v-snackbar v-model="newUpdateSnackbar">
      {{ $t("snackBarUpdateText") }} {{timeout / 1000}}s
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="reloadPage()" > {{ $t("snackBarUpdateRefreshBtn") }} </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
    export default {
        name: "NewUpdateSnackBar",
        data() {
            return {
                timeout: 5000
            }
        },
        methods:{
            reloadPage(){
                localStorage.clear();
                //window.location.reload()
            }
        },
        computed: {
            newUpdateSnackbar: {
                get(){
                    return this.$store.getters["newUpdateSnackbar"]
                },
                set(value){
                    this.$store.dispatch('updateNewUpdateSnackbar', value)
                }
            }
        },
        watch:{
            newUpdateSnackbar(newVal){
                if(newVal == true){
                    setTimeout(() => {
                        this.reloadPage()
                    }, this.timeout);
                    setInterval(() => {
                        this.timeout -= 1000
                    }, 1000);
                }
            }
        }
    }
</script>

<style scoped>

</style>