import { ContactService } from "./contact.service"

import { CONTACT_URL } from "@/.env"


export class RecallService extends ContactService {
    static get entity(){
        return 'contact'
    }

    static saveImmediateRecall(firstname, lastname, phoneNumber){
        let data = {
            firstname,
            lastname,
            phone1: phoneNumber,
            skillList: [
                {
                    skillUuid : this.skillUuid,
                    name : this.skillName,
                }
            ],
            campaign: {name: this.campaign}
        }
        let axiosInstance = this.request(CONTACT_URL, true)
        return axiosInstance.post(`${this.entity}/manage`, data)
    }
}