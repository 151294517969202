export default {
    namespaced: true,
    state: {
        center: null,
        formattedPins: JSON.parse(localStorage.getItem("IEC_PINS")) || [],
        pins: [],
        searchPin: null,
        bingToken: "An1LBwiA2em_GmqpT57Mei_cH498KOFiVREltMI9d2oBVOgktDTHirSKs89dITQ9"
    },
    getters: {
        center: state => {
            return state.center
        },
        pins: state => {
            return state.pins;
        },
        formattedPins: state => {
            return state.formattedPins;
        },
        searchPin: state => {
            return state.searchPin;
        },
        bingToken: state => {
            return state.bingToken;
        },
        getPinById: state => id => {
            return state.formattedPins.find(pin => pin.metadata.id === id)
        }

    },
    mutations: {
        updatePins(state, payload) {
            state.pins = payload;
            console.log("Updated pins", state.pins)
        },
        updateFormattedPins(state, payload) {
            state.formattedPins = payload;
            localStorage.setItem("IEC_PINS", JSON.stringify(state.formattedPins))
        },
        updateCenter(state, payload) {
            state.center = payload
        },
        updateSearchPin(state, payload) {
            state.searchPin = payload
        }
    },
    actions: {
        updatePins({commit}, payload) {
            console.log("Payload", payload)
            return new Promise(resolve => {
                commit('updatePins', payload);
                resolve()
            })
        },
        updateFormattedPins({commit}, payload) {
            console.log("Payload", payload)
            return new Promise(resolve => {
                commit('updateFormattedPins', payload);
                resolve()
            })
        },
        updateCenter({commit}, payload) {
            return new Promise(resolve => {
                commit('updateCenter', payload)
                resolve()
            })
        },
        updateSearchPin({commit}, payload) {
            return new Promise(resolve => {
                commit('updateSearchPin', payload)
                resolve()
            })
        }
    }
};