import axios from 'axios'

export class Http{
    constructor (baseApi, isJSON = false){
        this.instance = axios.create({})
        this.instance.defaults.baseURL = baseApi
        if(isJSON){
            this.instance.defaults.headers["Content-Type"] = "application/json"
        }
        return this.init()
    }

    init(){
        return this.instance
    }
}