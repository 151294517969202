<template>
    <v-bottom-sheet content-class="charging-sheet-wrapper" v-model="showCharging">
        <v-sheet class="rounded-t-lg bottom-sheet-border charging-sheet" elevation="4">
            <v-toolbar rounded="t-lg" flat dark color="primary" >
                <v-toolbar-title>{{$t("storeScanDialogHeader")}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="goToHome" >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <div class="pa-4">
                <ChargeComponent :startCharge="startCharging" :maxTime="chargeMaxTime" :maxVal="chargeMaxVal" :key="`charge-${startCharging}`" @finishCharge="showCharging = false" :withMQTT="false" showStartShop discount/>
            </div>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
    import { mapGetters } from "vuex";
    import ChargeComponent from "@/components/ChargeComponent";
    export default {
        components: {
            ChargeComponent,
        },
        name: "ChargingHomeDialog",
        methods: {
            goToHome() {
                this.showCharging = false
                if(this.$route.name != "Store"){
                    this.$router.push({name: "Store"})
                }
            }
        },
        computed: {
            ...mapGetters({
                chargeMaxTime: "charge/chargeMaxTime",
                chargeMaxVal: "charge/chargeMaxVal",
                startCharging: "charge/startCharging",
            }),
            showCharging: {
                get(){
                    return this.$store.getters["charge/showChargingDialog"]
                },
                set(value){
                    this.$store.dispatch('charge/updateShowChargingDialog', value)
                } 
            }
        },
    }
</script>
<style scoped>

    >>> .charging-sheet-wrapper.v-dialog{
        max-height: 100% !important;
    }
    .charging-sheet{
        height: 99.5vh;
    }
</style>