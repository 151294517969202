const moment = require("moment")
export const utilsMixin = {
    methods:{
        formatPrice(price, decimals = 2){
            return parseFloat(price).toFixed(decimals)
        },
        formatNumber(num, decimals = 2){
            return parseFloat(num).toFixed(decimals)
        },
        formatTimeFromSeconds(valueInSec){
            var date = new Date(0);
            date.setSeconds(valueInSec);
            return date.toISOString().substr(11, 8);
        },
        getCurrentDate(format = 'DD/MM/YYYY, HH:mm:ss'){
           return moment().format(format);
        },
        formatDate (date, format = 'DD/MM/YYYY') {
          if (!date) return null
          return moment(date).format(format)
        },
        parseDate (date, format = 'DD-MM-YYYY') {
          if (!date) return null
          return moment(date).format(format)
        },
        getNextAvailableSlot(){
            let time = this.getCurrentDate("HH:mm")
            let hours = parseInt(time.split(":")[0])
            let minutes = parseInt(time.split(":")[1])
            if(minutes >= 50){
                hours += 1
                hours = ("0" + hours).substr(1)
                minutes = "00"
            }else{
                minutes += 10

            }
            return [hours, minutes].join(":")
        },
        addHoursToTime(time, hoursToAdd){
            console.log(this.getCurrentDate("DD/MM/YYYY") + ` ${time}`)
            return moment(this.getCurrentDate("DD/MM/YYYY") + ` ${time}`, "DD/MM/YYYY HH:mm").add(hoursToAdd, 'hours').format("HH:mm")
        },
        getDurationBetweenDates(startDate, endDate){
            let duration = moment.duration(moment(this.getCurrentDate("DD/MM/YYYY") + ` ${endDate}`, "DD/MM/YYYY HH:mm").diff(moment(this.getCurrentDate("DD/MM/YYYY") + ` ${startDate}`, "DD/MM/YYYY HH:mm")));
            let minutes = duration.asMinutes();
            return minutes
        }
    }
}