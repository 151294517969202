import {RecallService} from "@/services/recall.service"

export default {
    namespaced: true,
    state: {
        showDialog: false,
        loading: false,
        firstName: "",
        lastName: "",
        phoneNumber: ""
    },
    getters: {
        showDialog: state => {
            return state.showDialog;
        },
        loading: state => {
            return state.loading;
        },
        firstName: state => {
            return state.firstName;
        },
        lastName: state => {
            return state.lastName;
        },
        phoneNumber: state => {
            return state.phoneNumber;
        }
    },
    mutations: {
        updateShowDialog(state, payload) {
            state.showDialog = payload;
        },
        updateLoading(state, payload){
            state.loading = payload;
        },
        updateFirstName(state, payload){
            state.firstName = payload;
        },
        updateLastName(state, payload){
            state.lastName = payload;
        },
        updatePhoneNumber(state, payload){
            state.phoneNumber = payload;
        }
    },
    actions: {
        updateShowDialog({commit}, payload) {
            commit('updateShowDialog', payload);
        },
        updateLoading({commit}, payload) {
            commit('updateLoading', payload);
        },
        updateFirstName({commit}, payload) {
            commit('updateFirstName', payload);
        },
        updateLastName({commit}, payload) {
            commit('updateLastName', payload);
        },
        updatePhoneNumber({commit}, payload) {
            commit('updatePhoneNumber', payload);
        },
        async saveRecallRequest({getters, commit}){
            return new Promise((resolve, reject) => {
                commit('updateLoading', true)
                try{
                    console.log(RecallService.saveImmediateRecall(getters.firstName, getters.lastName, getters.phoneNumber))
                    //let response = await RecallService.saveImmediateRecall(getters.firstName, getters.lastName, getters.phoneNumber)
                    resolve()
                }catch(e){
                    reject()
                }finally{
                    commit('updateLoading', false)
                }
            
            } )
        }
    }
};