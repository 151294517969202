import { OCULAR_URL } from "@/.env"
import { BaseService } from "./base"

let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI4IiwianRpIjoiZDljZDhlYTA1ZjgyMzBkOGIxNDhhMjY3NjRlMjg0ODYyNGY0N2VjMTFhODFiYjI3MWMxY2Y2OWEwOTE1YmIyODA4ZGI3ZTg5YWNmMzgzYmIiLCJpYXQiOjE2NDE1NzMwMzAsIm5iZiI6MTY0MTU3MzAzMCwiZXhwIjoxNjczMTA5MDMwLCJzdWIiOiIyNDkiLCJzY29wZXMiOltdfQ.lSGULCwcZBDuglv6R-_-LKwBUfMHYLR7yS82q1mw_FQSXUFx9MFTn7rPf4341299HaXatmNyyqL_ESOhQQXcMsxccA27q6MPL6xPw9umQ4ShFtebxZgqgSgsujTgkA9iZUr44qO9vD-9Mh9WolxdWShUZMTCg4SRSdO5zHQLv4qyzq1Bn1QErxGQXL2L5qm-iG1LbOoNJo4y0qqlvQhYfqI9heNTPoAYuWSlUqUuEuvYts3w_JFaUB3gG1C67b5ELX7dY_k4Dr282ZfBHVtXbfTEbhIlLDwCcFMeQaSr3ZL9W6bktiBAZhxKSz17SYj8XRKvMaZygh_qe4sXGyxBRmLLTFlGwWNoLGIN2Onc0C88TNDgdJpqvpiFDBneD1oAPUA-pYFtwbRiXvmdichIybjUmQ1AF1L9njY2JAl_P_oJ7-jqYeFf5LTym00b1tCggA39aMuesUDleYLHlZUVhjbmf2Z4raQOs2p2NPPIQrwqxTNf_WQv4crtvMvqlyv0XTlrcfr2j0khMCpib9pnxe8d1UgY5N6hNXSMh14-vIXoifr7iKWvRB6uxfazCBbMM4Pl1Q0H6e1r9h-zMNioHsmy-Hmy5GShho5sBrlwJ9IQbbEPE8wDWmC1a71GmLD6RdHxQFp64Uw5K22p8EMkPIof6QexggBF1b_sk4Io4sM"
let config = {
    headers: {
      "Authorization": `Bearer ${token}`,
    }
  }

export class OcularService extends BaseService {
    static get entity(){
        return 'ocular'
    }
    
    static get authenticationParams(){
        return {
            "grant_type":"password",
            "username":"gertjan.andries@worldline.com",
            "password":"JbTbJda6bPf9dVPU"
        }
    }

    static getToken(){
        const params = new URLSearchParams()
        params.append('grant_type', this.authenticationParams.grant_type)
        params.append('username', this.authenticationParams.username)
        params.append('password', this.authenticationParams.password)
        return this.request(OCULAR_URL, true).post(`/oauth/token`, params, {
            headers: {
              "Authorization": `Basic ODpzWWh6ODdUM0Uxd1FkSmNuS0ZzMWpodG4xeWxENTBHUU9CZjhXeTk5`,
            }
          })
    }
    static getCustomers(){
        return this.request(OCULAR_URL, true).get(`/dashboard/v2/version`, config)
    }
}