
import { mapActions, mapGetters } from "vuex"
export const pageInfoMixin = {
    data(){
        return {
        }
    },
    computed:{
        ...mapGetters(["headerHeight", "vh"])
    },
    created(){
        this.$emit("pageCreated", this.page)
    },
    methods:{
        ...mapActions(["updateVh", "updateHeaderHeight"]),
        updatePageInfo(){
            this.$emit("pageCreated", this.page)
        }
    },
    watch:{
        page(oldVal, newVal){
            console.log(oldVal, newVal)
        }
    }
}