import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/pin/:pinId',
    name: 'PinInfo',
    component: () => import('../views/Info.vue')
  },
  {
    path: '/itinerary',
    name: 'Itinerary',
    component: () => import('../views/Itinerary.vue')
  },
  {
    path: '/charging',
    name: 'Charging',
    component: () => import('../views/Charging.vue')
  },
  {
    path: '/ticket',
    name: 'Ticket',
    component: () => import('../views/Ticket.vue')
  },
  {
    path: '/orders',
    name: 'MyOrders',
    component: () => import('../views/MyOrders.vue')
  },
  {
    path: '/',
    name: 'menu',
    component: () => import('../views/Menu.vue')
  },
  {
    path: '/store',
    name: 'Store',
    meta: {
      showDefaultHeader: false
    },
    component: () => import('../views/Store.vue')
  },
  {
    path: '/ev-carging',
    name: 'EVCharging',
    component: () => import('../views/EVCharging.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
