import {BasketService} from "@/services/basket.service"

export default {
    namespaced: true,
    state: {
        localBasket: JSON.parse(localStorage.getItem("IEC_PAY_DRIVE_BASKET")) || {id: 0, basketProducts: [], totalAmount: 0},
        lastTicket : JSON.parse(localStorage.getItem("IEC_PAY_DRIVE_TICKET")) || null
    },
    getters: {
        localBasket: state => {
            return state.localBasket;
        },
        totalAmount: state => {
            return state.localBasket.basketProducts.reduce((acc, basketProduct) => acc += basketProduct.qty * basketProduct.product.price, 0);
        },
        nbrProducts: state => {
            return state.localBasket.basketProducts.reduce((acc, basketProduct) => acc += basketProduct.qty, 0);
        },
        complexId: state => {
            return state.localBasket.complexId || null;
        },
        basketProductsFormatted: state => {
            if(!state.localBasket) return []
            return state.localBasket.basketProducts.map(basketProduct => {
                return {
                    productId: basketProduct.product.id,
                    qty: basketProduct.qty
                }
            })
        },
        lastTicket: state => {
            return state.lastTicket
        },
        qtyByProductId: state => id => {
            let qty = 0
            console.log(id)
            let foundProduct = state.localBasket.basketProducts.find(basketProduct => { return basketProduct.product.id == id })
            if(foundProduct){
                qty = foundProduct.qty
            }
            console.log(foundProduct)
            return qty
        }
    },  
    mutations: {
        resetLocalBasket(state) {
            state.localBasket = {id: 0, basketProducts: [], totalAmount: 0};
            localStorage.setItem("IEC_PAY_DRIVE_BASKET", JSON.stringify(state.localBasket))
        },
        updateLocalBasket(state, payload) {
            state.localBasket = payload;
            localStorage.setItem("IEC_PAY_DRIVE_BASKET", JSON.stringify(state.localBasket))
        },
        updateLastTicket(state, payload) {
            state.lastTicket = payload;
            localStorage.setItem("IEC_PAY_DRIVE_TICKET", JSON.stringify(state.lastTicket))
        },
        addProduct(state, payload) {
            let added = false
            state.localBasket.basketProducts.some(basketProduct => {
                if(payload.id == basketProduct.product.id){
                    basketProduct.qty += 1
                    added = true
                    return true
                }
            })
            if(!added){
                state.localBasket.basketProducts.push({
                    id: payload.id,
                    product: payload,
                    qty: 1
                })
            }
            state.localBasket.totalAmount += payload.price
            localStorage.setItem("IEC_PAY_DRIVE_BASKET", JSON.stringify(state.localBasket))
        },
        deleteProduct(state, payload) {
            state.localBasket = payload;
        }
    },
    actions: {
        updateBasket({ getters, rootGetters, commit, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try{
                    let {data, status} = await BasketService.manageBasketProducts(rootGetters['customer/customerToken'], {productsCart : getters.basketProductsFormatted})
                    if(status == 200 && !data.error){
                        let customerCurrentBaskets = await dispatch("getBasket")
                        console.log(customerCurrentBaskets)
                        if(customerCurrentBaskets.length > 0){
                            commit("updateLocalBasket", customerCurrentBaskets[0])
                            resolve()
                        }else{
                            reject()
                        }
                    }else{
                        reject()
                    }
                }catch(e){
                    console.error(e)
                    reject()
                }

            })
        },
        addProduct( {commit}, payload ){
            commit("addProduct", payload)
        },
        removeProduct( {commit}, payload ){
            
        },
        emptyBasket({commit}, payload){
            commit('resetLocalBasket')
        },
        validBasket({ getters, commit, dispatch, rootGetters }, payload){
            return new Promise(async (resolve, reject) => {
                try{
                    await dispatch("updateBasket")
                    let {data, status} = await BasketService.validBasket(rootGetters['customer/customerToken'], payload.comment)
                    if(status == 200 && !data.error){
                        let ticket = await BasketService.getBasketByComplexId(getters.complexId)
                        commit('updateLastTicket', ticket.data)
                        commit('resetLocalBasket')
                        resolve()
                    }else{
                        reject()
                    }
                }catch(e){
                    console.error(e)
                    reject()
                }

            })
        },
        getBasket({rootGetters}){
            return new Promise(async (resolve, reject) => {
                try{
                    let {data, status} = await BasketService.getBasketByState(rootGetters['customer/customerToken'], "IN_PROGRESS")
                    if(status == 200 && !data.error){
                        resolve(data)
                    }else{
                        reject()
                    }
                }catch(e){
                    console.error(e)
                    reject()
                }

            })
        },
        getAllFinishedOrders({rootGetters}){
            return new Promise(async (resolve, reject) => {
                try{
                    let {data, status} = await BasketService.getBasketByState(rootGetters['customer/customerToken'], "FINISH")
                    if(status == 200 && !data.error){
                        let orders = data.map(order => {
                            try{
                                order.comment = JSON.parse(order.comment)
                            }catch(e){
                                
                            }finally{
                                return order
                            }
                        })
                        resolve(orders)
                    }else{
                        reject()
                    }
                }catch(e){
                    console.error(e)
                    reject()
                }

            })
        },
    }
};