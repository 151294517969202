import { utilsMixin } from "@/mixins/utilsMixin"
export default {
    namespaced: true,
    state: {
        showDialog: false,
        selectedPin: JSON.parse(sessionStorage.getItem("IEC_SELECTED_PIN")) || null ,
        selectedPinBooking: null,
        bookedPins: JSON.parse(localStorage.getItem("IEC_BOOKED_PINS")) || [],
        selectedDate: utilsMixin.methods.getCurrentDate('YYYY-MM-DD'),
        selectedTime: utilsMixin.methods.getNextAvailableSlot(),
        selectedEndTime: null,
    },
    getters: {
        showDialog: state => {
            return state.showDialog;
        },
        selectedPin: state => {
            return state.selectedPin;
        },
        selectedPinBooking: state => {
            if(state.selectedPin){
                let booked = state.bookedPins.filter(pin => pin.pinId == state.selectedPin.metadata.id)
                if(booked.length > 0){
                    return booked[0]
                }
            }
            return null
        },
        bookedPins: state => {
            return state.bookedPins;
        },
        selectedDate: state => {
            return state.selectedDate;
        },
        selectedTime: state => {
            return state.selectedTime;
        },
        selectedEndTime: state => {
            return state.selectedEndTime;
        }
    },
    mutations: {
        updateShowDialog(state, payload) {
            state.showDialog = payload;
        },
        updateSelectedPin(state, payload) {
            state.selectedPin = payload;
            sessionStorage.setItem("IEC_SELECTED_PIN", JSON.stringify( {id: state.selectedPin.id, metadata: state.selectedPin.metadata}))
        },
        updateBookedPins(state, payload) {
            state.bookedPins = payload;
            localStorage.setItem("IEC_BOOKED_PINS", JSON.stringify(state.bookedPins))
        },
        updateSelectedDate(state, payload) {
            state.selectedDate = payload;
        },
        updateSelectedTime(state, payload) {
            state.selectedTime = payload;
        },
        updateSelectedEndTime(state, payload) {
            state.selectedEndTime = payload;
        }
    },
    actions: {
        updateShowDialog({commit}, payload) {
            commit('updateShowDialog', payload);
        },
        updateSelectedPin({commit}, payload) {
            commit('updateSelectedPin', payload);
        },
        updateBookedPins({commit}, payload) {
            commit('updateBookedPins', payload);
        },
        updateSelectedDate({commit}, payload) {
            commit('updateSelectedDate', payload);
        },
        updateSelectedTime({commit}, payload) {
            commit('updateSelectedTime', payload);
        },
        updateSelectedEndTime({commit}, payload) {
            commit('updateSelectedEndTime', payload);
        }
    }
};