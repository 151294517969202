import Vue from 'vue'
import Vuex from 'vuex'
import bookingDialog from './modules/bookingDialog'
import recallDialog from './modules/recallDialog'
import pins from './modules/pins'
import basket from './modules/basket'
import customer from './modules/customer'
import ocular from './modules/ocular'
import charge from './modules/charge'
Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    loader: false,
    loaderText: "loadingDefaultText",
    showPaymentDialog: false,
    paymentDone: false,
    vh: 0,
    headerHeight: 0,
    lang: localStorage.getItem("IEC_PAY_AND_DRIVE_LANG") || "en",
    newUpdateSnackbar: false
  },
  getters:{
    loader: state => {
      return state.loader
    },
    loaderText: state => {
      return state.loaderText
    },
    showPaymentDialog: state => {
      return state.showPaymentDialog
    },
    paymentDone: state => {
      return state.paymentDone
    },
    vh: state => {
      return state.vh
    },
    headerHeight: state => {
      return state.headerHeight
    },
    lang: state => {
      return state.lang
    },
    newUpdateSnackbar: state => {
      return state.newUpdateSnackbar
    }
  },
  mutations: {
    updateLoader(state, payload){
      state.loader = payload;
    },
    updateLoaderText(state, payload){
      state.loaderText = payload;
    },
    updatePaymentDialogState(state, payload){
      state.showPaymentDialog = payload;
    },
    updatePaymentDone(state, payload){
      state.paymentDone = payload;
    },
    updateHeaderHeight(state, payload){
      state.headerHeight = payload;
    },
    updateVh(state, payload){
      state.vh = payload;
    },
    updateLang(state, payload){
      state.lang = payload;
      localStorage.setItem("IEC_PAY_AND_DRIVE_LANG", state.lang)
    },
    updateNewUpdateSnackbar(state, payload){
      state.newUpdateSnackbar = payload;
    }
  },
  actions: {
    UPDATE_LOADER({ commit }, payload){
      commit("updateLoader", payload)
    },
    updatePaymentDialogState({ commit }, payload){
      commit("updatePaymentDialogState", payload)
    },
    updateLoaderText({ commit }, payload){
      commit("updateLoaderText", payload)
    },
    resetLoaderText({ commit }){
      commit("updateLoaderText", "loadingDefaultText")
    },
    updatePaymentDone({ commit }, payload){
      commit("updatePaymentDone", payload)
    },
    updateHeaderHeight({ commit }, payload){
      commit("updateHeaderHeight", payload)
    },
    updateVh({ commit }, payload){
      commit("updateVh", payload)
    },
    updateLang({ commit }, payload){
      commit("updateLang", payload)
    },
    updateNewUpdateSnackbar({ commit }, payload){
      commit("updateNewUpdateSnackbar", payload)
    }
  },
  modules: {
    recallDialog,
    bookingDialog,
    pins,
    basket,
    customer,
    ocular,
    charge
  }
})
