<template>
    <v-dialog v-model="showDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dense dark color="primary">
                <v-btn icon dark @click="showDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{selectedPin.metadata.title}}</v-toolbar-title>
            </v-toolbar>
            <div v-if="selectedPin" class="mt-4 mb-2 d-flex flex-column align-center pin-info">
                <span class="text-h6">{{$t("bookingSlotWelcomeText", {title: selectedPin.metadata.title})}}</span>
                <span class="text-caption" v-if="formattedAddress != null">{{formattedAddress}}</span>
                <v-row align="center" class="mb-1 mt-0">
                    <v-rating :value="parseFloat(selectedPin.metadata.rating)" color="amber" dense half-increments readonly size="12"></v-rating>
                    <div class="grey--text text-caption"> {{selectedPin.metadata.rating}} ({{selectedPin.metadata.reviews}}) </div>
                </v-row>
                <div class="my-4 d-flex align-center justify-center chargers-wrapper">
                    <div v-for="plug in availablePlugs" :key="`plug-${plug.id}`" :class="{'mx-4 px-5 py-4 d-flex flex-column rounded-xl charger-wrapper': true, 'active': plug.id == selectedPlugId}" @click="updateSelectedPower(plug.id)">
                        <span class="text-caption">{{plug.power}}kW</span>
                        <span class="text-caption">{{plug.title}}</span>
                        <span class="text-caption">{{formatPrice(selectedPin.metadata.pricePerMinutes * plug.priceFactor, 3)}}€/min</span>
                    </div>
                </div>
            </div>
            <div class="ma-0 pa-2">
                <div class="d-flex flex-column align-center">
                    <span class="my-1 text-center text-h6">{{$t("bookigSlotChoosenSlotDate" ,{date: computedDateFormatted, timeBegin: filterTime, timeEnd: filterEndTime, duration: chargeDuration})}}</span>
                    <v-row v-if="false" class="align-center justify-center" no-gutters>
                        <v-col class="mr-1" cols="6">
                            <v-menu v-model="showDatePicker" ref="menu" content-class="filter-picker mt-2" :close-on-content-click="false" transition="scale-transition" offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="computedDateFormatted" label="Date" hide-details dense readonly solo disabled v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="date" @input="showDatePicker = false" :min="currentDate.toISOString()" full-width>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="showDatePicker = false">{{$t("bookingSlotCancelBtn")}}</v-btn>
                                    <v-btn text color="primary" @click="showDatePicker = false">{{$t("bookingSlotOkBtn")}}</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="my-1" cols="4">
                            <v-menu v-model="showTimePicker" ref="menu" :close-on-content-click="false" content-class="filter-picker mt-2" transition="scale-transition" offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="time" label="Début" hide-details dense solo disabled readonly v-on="on"></v-text-field>
                                </template>
                                <v-time-picker v-model="time" :min="getCurrentDate('DD/MM/YYYY') != computedDateFormatted ? null : formatDate(currentDate, 'HH:mm')" format="24hr" @input="showTimePicker = false" full-width>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="showTimePicker = false">{{$t("bookingSlotCancelBtn")}}</v-btn>
                                    <v-btn text color="primary" @click="showTimePicker = false">{{$t("bookingSlotOkBtn")}}</v-btn>
                                </v-time-picker>
                            </v-menu>
                        </v-col>
                        <v-col v-if="validSlot" class="my-1" cols="10">
                            <p v-if="selectedPinBooking != null" class="my-1 text-center text-subtitle-2">{{$t("bookigSlotChoosenDuration")}}</p>
                            <p v-else class="my-1 text-center text-subtitle-2">{{$t("bookigSlotAskSlotDuration")}}</p>
                            <v-select :items="items" v-model="chargeDuration" :label="$t('bookingSlotChargeDuration')" return-object solo dense hide-details></v-select>
                        </v-col>
                    </v-row>
                    <p v-if="validSlot" class="mt-10 mb-0 text-h6">{{$t("bookingSlotEstimatedTotal")}}: <b class="primary--text">{{estimatedTotal}}€</b> </p>
                    <p v-if="validSlot" class="mt-0 mb-0 text-caption">{{$t("bookingSlotCompleteEstimatedTotal")}}*: <b class="primary--text">{{formatPrice(selectedPin.metadata.pricePerMinutes * selectedPlug.priceFactor * 90)}}€</b></p>
                    <small class="mt-0 red--text text-caption">*{{$t("bookingSlotEstimatedTotalAvailability")}}</small> 
                </div>
            </div>
            <v-spacer></v-spacer>
            <v-card-actions class="my-2 d-flex flex-column align-stretch actions">
                <v-checkbox class="my-2 align-self-center" v-model="validationCheckbox" color="primary" value="normal" small hide-details >
                    <template v-slot:label>
                        <span class="text-caption">{{$t('bookingSlotCheckboxValidation')}} <span class="blue--text text-decoration-underline" @click.prevent="termsDialog = true">{{$t('bookingSlotCheckboxValidationTerms')}}</span></span>
                    </template>
                </v-checkbox>
                <v-btn class="mt-auto" color="primary" :disabled="!validationCheckbox || !validSlot" @click="handleStepForward()">{{$t("bookingSlotValidate")}}</v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="termsDialog" width="500" >
            <v-card>
                <v-card-title class="text-h5 grey lighten-2 terms-dialog-header">{{$t('bookingSlotCheckboxValidationTerms')}}</v-card-title>
                <v-card-text class="pa-2">
                    <ul>
                        <li>{{$t("bookingSlotCheckboxValidationTermsText")}}</li>
                    </ul>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="termsDialog = false" >{{$t("bookingSlotTermsDialogAccept")}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
    import { mapGetters, mapActions } from "vuex"
    export default {
        data(){
            return {
                time: null,
                step: 1,
                date: null,
                currentDate: new Date(),
                sliderVal: 25,
                dontKnow: false,
                showDatePicker: false,
                showTimePicker: false,
                chargeDuration: 60,
                validationCheckbox: false,
                termsDialog: false,
                items: [
                    {
                        text: "10 min",
                        value: 10
                    },
                    {
                        text: "20 min",
                        value: 20
                    },
                    {
                        text: "30 min",
                        value: 30
                    },
                    {
                        text: "40 min",
                        value: 40
                    },
                    {
                        text: "50 min",
                        value: 50
                    },
                    {
                        text: "1 h",
                        value: 60
                    },
                    {
                        text: "Je ne sais pas",
                        value: null
                    }
                ],
                selectedPlugId: 0
            }
        },
        computed:{
            ...mapGetters({
                selectedPin: "bookingDialog/selectedPin",
                bookedPins: "bookingDialog/bookedPins",
                paymentDone: "paymentDone",
                filterDate: "bookingDialog/selectedDate",
                filterTime: "bookingDialog/selectedTime",
                filterEndTime: "bookingDialog/selectedEndTime",
            }),
            showDialog: {
                get(){
                    return this.$store.getters["bookingDialog/showDialog"]
                },
                set(value){
                    this.$store.dispatch('bookingDialog/updateShowDialog', value)
                }
            },
            pinAlreadyBooked(){
                if(this.selectedPin){
                    return this.bookedPins.reduce((acc, pin) => acc || pin.pinId == this.selectedPin.metadata.id, false)
                }
                return false
            },
            selectedPinBooking(){
                if(this.selectedPin){
                    let booked = this.bookedPins.filter(pin => pin.pinId == this.selectedPin.metadata.id)
                    if(booked.length > 0){
                        return booked[0]
                    }
                }
                return null
            },
            formattedAddress(){
                if(this.selectedPin){
                    return this.selectedPin.metadata.address.split("<br>").join(" ")
                }
                return null
            },
            batteryClass(){
                let batteryClass = "power-red"
                if(this.sliderVal > 25 && this.sliderVal <= 50){
                    batteryClass = "power-orange"
                }
                if(this.sliderVal > 50){
                    batteryClass = "power-green"
                }
                return batteryClass
            },
            validSlot(){
                return this.date != null && this.time != null
            },
            computedDateFormatted () {
                return this.formatDate(this.date)
            },
            estimatedTotal(){
                if(this.selectedPin){
                    let total = this.formatNumber( this.chargeDuration * parseFloat(this.selectedPin.metadata.pricePerMinutes * this.selectedPlug.priceFactor)) 
                    return total < 22.23 ? total : 22.23
                }
                return null
            },
            
            availablePlugs(){
                return [
                    {
                        id: 0,
                        title: "TYPE 2",
                        power: this.selectedPin.metadata.power,
                        priceFactor: 1
                    },
                    {
                        id: 1,
                        title: "COMBO CCS",
                        power: parseInt(this.selectedPin.metadata.power) + 5,
                        priceFactor: 1.5
                    }
                ]
            },
            selectedPlug(){
                return this.availablePlugs.filter(plug => plug.id == this.selectedPlugId)[0]
            }
        },
        methods:{
            ...mapActions({
                updateBookedPins: "bookingDialog/updateBookedPins",
                updateLoader: "UPDATE_LOADER",
                updateLoaderText: "updateLoaderText",
                updatePaymentDialogState: "updatePaymentDialogState",
            }),
            handleSaveDate(){
                let updated = false
                this.bookedPins.forEach(pin => {
                    if(pin.pinId === this.selectedPin.metadata.id){
                        pin.bookedDate = this.date
                        pin.bookedTime = this.time
                        pin.dontKnow = this.dontKnow
                        pin.sliderVal = this.sliderVal
                        pin.chargeDuration = this.chargeDuration,
                        pin.selectedPlug = this.selectedPlugId
                        updated = true
                    }
                })
                if(!updated){
                    let pin = {
                        pinId: this.selectedPin.metadata.id,
                        bookedDate: this.date,
                        bookedTime: this.time,
                        dontKnow: this.dontKnow,
                        sliderVal: this.sliderVal,
                        chargeDuration: this.chargeDuration,
                        selectedPlug: this.selectedPlugId
                    }
                    this.bookedPins.push(pin)
                }
                this.updateBookedPins(this.bookedPins)
                this.showDialog = false
                this.updateLoader(false)
                this.date = null
                this.time = null
                this.chargeDuration = null
                this.step = 1
            },
            handleStepBackward(){
                if(this.step > 1){
                    this.step -= 1
                }
            },
            handleStepForward(){
                this.updateLoaderText("loadingPaymentRedirection")
                this.updateLoader(true)
                setTimeout(() => {
                    this.updatePaymentDialogState(true)
                }, 2000);
            },
            updateSelectedPower(plugId){
                this.selectedPlugId = plugId
            }
        },
        created(){
            this.date = this.filterDate
            this.time = this.filterTime
            this.chargeDuration = this.getDurationBetweenDates(this.filterTime , this.filterEndTime)
        },
        watch: {
            showDialog(newVal){
                if(newVal && this.selectedPinBooking){
                    this.date = this.selectedPinBooking.bookedDate || this.filterDate
                    this.time = this.selectedPinBooking.bookedTime || this.filterTime
                    this.dontKnow = this.selectedPinBooking.dontKnow
                    this.sliderVal = this.selectedPinBooking.sliderVal
                    this.chargeDuration = this.selectedPinBooking.chargeDuration || this.getDurationBetweenDates(this.filterTime, this.filterEndTime)
                }else{
                    this.date = this.filterDate
                    this.time = this.filterTime
                    this.chargeDuration = this.getDurationBetweenDates(this.filterTime, this.filterEndTime)
                    this.validationCheckbox = false
                }
            },
            paymentDone(){
                if(this.paymentDone){
                    this.handleSaveDate()
                }
            }
        }
    }
</script>

<style scoped>
    .charger-wrapper {
        border: solid 1px #46b8a6;
    }
    .charger-wrapper.active{
        background: #46b8a6;
        color: white;
    }
    
    .cls-1 {
        fill: black;
    }
    
    .power{
        top: 40;
        transition: fill .4s ease;
    }
    .power-red{
        fill: #f35353;
    }
    .power-orange{
        fill: #FFEB78;
    }
    .power-green{
        fill: #46b8a6;
    }
    .power-gray{
        fill: gray;
    }
    .battery-wrapper{
        width: 100%;
    }
    .battery-icon{
        align-self: center;
        margin-left: 48px;
        transform: scale(.57);
    }
    .battery-icon.disabled{
        opacity: .70;
    }
    .actions{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
    }
    .battery-slider{
        width: 100%;
    }
    
    .filter-picker{
        left: 0 !important;
        right: 0 !important;
        width: 100%;
        max-width: 100%;
    }
    .terms-dialog-header{
        word-break: break-word;
    }
</style>