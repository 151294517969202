import { WS_URL } from "@/.env"
import { BaseService } from "./base"


export class BasketService extends BaseService {
    static get entity(){
        return 'basket'
    }

    static getBasketByState(token, state){
        return this.request(WS_URL, true).get(`${this.entity}/get/state?token=${token}&state=${state}`)
    }

    static manageBasketProducts(token, products){
        const params = new URLSearchParams()
        params.append('token', token)
        params.append('products', JSON.stringify(products))
        return this.request(WS_URL, true).post(`${this.entity}/manage/products`, params)
    }

    static validBasket(token, comment = null, isSuspect = false, orderOrigin = "IEC_PAY_AND_DRIVE"){
        const params = new URLSearchParams()
        params.append('token', token)
        params.append('orderOrigin', orderOrigin)
        if(comment != null){
            params.append('comment', comment)
        }
        if(isSuspect){
            params.append('isSuspect', isSuspect)
        }
        return this.request(WS_URL, true).post(`${this.entity}/valid`, params)
    }
    static getBasketByComplexId(complexId){
        return this.request(WS_URL, true).get(`${this.entity}/get/${complexId}`)
    }
}