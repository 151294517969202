<template>
    <v-dialog v-model="showDialog">
        <v-card v-if="!showSuccess">
            <v-card-title class="text-h5">
                {{$t("recallDialogTitle")}}
            </v-card-title>

            <v-card-text>
                <div class="d-flex flex-column">
                    <div class="d-flex">
                    <v-text-field v-model="firstName" class="mx-2" :label="$t('recallDialogFirstName')"></v-text-field>
                    <v-text-field v-model="lastName" class="mx-2" :label="$t('recallDialogLastName')"></v-text-field>
                </div>
                <v-text-field v-model="phoneNumber" class="mx-2" :label="$t('recallDialogTel')"></v-text-field>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="showDialog = false" small>{{$t("recallDialogCancelBtn")}}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" :loading="loading" small @click="handleSavedRecallRequest()">{{$t("recallDialogValidateBtn")}}</v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-else>
            <v-card-title class="text-h5">
                {{$t("recallDialogSuccessText")}} 
            </v-card-title>

            <v-card-text>
                <div class="d-flex flex-column align-center">
                    <v-icon size="64" class="animate__animated animate__zoomIn" color="green">mdi-check</v-icon>
                    <span class="my-3 text-center text-caption">{{$t("recallDialogSuccessText2")}} </span>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" small @click="showDialog = false">{{$t("recallDialogCloseBtnLabel")}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapGetters, mapActions } from "vuex"

    export default {
        name : "updateShowDialog",
        data(){
            return{
                showSuccess: false
            }
        },
        computed: {
            ...mapGetters({
                loading: "recallDialog/loading"
            }),
            showDialog: {
                get(){
                    return this.$store.getters["recallDialog/showDialog"]
                },
                set(value){
                    this.$store.dispatch('recallDialog/updateShowDialog', value)
                }
            },
            firstName: {
                get(){
                    return this.$store.getters["recallDialog/firstName"]
                },
                set(value){
                    this.$store.dispatch('recallDialog/updateFirstName', value)
                }
            },
            lastName: {
                get(){
                    return this.$store.getters["recallDialog/lastName"]
                },
                set(value){
                    this.$store.dispatch('recallDialog/updateLastName', value)
                }
            },
            phoneNumber: {
                get(){
                    return this.$store.getters["recallDialog/phoneNumber"]
                },
                set(value){
                    this.$store.dispatch('recallDialog/updatePhoneNumber', value)
                }
            },
            selectedLocale: {
                get(){
                    return this.$store.getters["lang"]
                },
                set(value){
                    this.$store.dispatch('updateLang', value)
                }
            }
        },
        methods: {
            ...mapActions({
                saveRecallRequest: "recallDialog/saveRecallRequest"
            }),
            async handleSavedRecallRequest(){
                try{
                    await this.saveRecallRequest()
                }catch(e){
                    console.error(e)
                }finally{
                    this.showSuccess = true
                
                }
            }
        },
        watch:{
            showDialog(newVal){
                if(!newVal){
                    this.showSuccess = false
                    this.firstName = ""
                    this.lastName = ""
                    this.phoneNumber = ""
                }
            }
        }
    }
</script>

<style scoped>

</style>