<template>
    <div v-show="showHeader" class="rounded-br-xl elevation-4 header" ref="header">
        <v-app-bar color="transparent" flat prominent dark>
            <div class="d-flex flex-column header-actions-wrapper">
                <div class="d-flex header-actions">
                    <v-btn  @click="drawer = true" size="32" icon>
                        <v-icon size="32">mdi-menu</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-img class="drawer-header-logo" lazy-src="@/assets/img/Worldline-Coconut-Horizontal.png" width="50px" height="50px" src="@/assets/img/Worldline-Coconut-Horizontal.png" contain></v-img>
                    <v-spacer></v-spacer>
                    <!-- <v-menu offset-y bottom left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn dark icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-menu offset-y offset-x left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item v-bind="attrs" v-on="on">
                                        <v-list-item-title class="d-flex align-center" color="primary">{{$t("languagesLabel")}}</v-list-item-title>
                                    </v-list-item>
                                </template>


                            </v-menu>
                    
                            <v-list-item @click="clearCache()">
                                <v-list-item-title class="d-flex align-center" color="primary">{{$t("clearCacheLabel")}}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu> -->
                </div>
                <v-app-bar-title class="d-flex align-center mt-auto header-title">
                    <v-btn v-show="showBack" @click="$router.go(-1)" icon>
                        <v-icon large>mdi-chevron-left</v-icon>
                    </v-btn>
                    <span v-if="title != null" class="mx-1 font-weight-bold">{{$t(title)}}</span>
                </v-app-bar-title>
            </div>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" absolute temporary>
            <div class="d-flex flex-column align-center">
                <div class="drawer-wrapper d-flex align-center py-4">
                    <v-btn class="drawer-header-icon" icon @click="drawer = false">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-img class="drawer-header-logo" lazy-src="@/assets/img/wl-horizontal-logo.png" src="@/assets/img/wl-horizontal-logo.png"></v-img>
                    <v-spacer></v-spacer>
                    <v-menu class="custom-menu" offset-y bottom left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="clearCache()">
                                <v-list-item-title class="d-flex align-center" color="primary">{{$t("clearCacheLabel")}}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="restartOcularScene()">
                                <v-list-item-title class="d-flex align-center" color="primary">{{$t("restartOcularBtnLabel")}}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="ocularFullScreen()">
                                <v-list-item-title class="d-flex align-center" color="primary">{{$t("ocularFullScreenBtnLabel")}}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
                <v-divider></v-divider>
                <v-list dense>
                    <template v-for="item in items">
                        <v-list-item class="my-3 align-center" :key="item.title" link @click="handleItemClick(item)">
                            <span v-if="item.customIcon" :class="'drawer-icon primary--text icon-' + item.icon"></span>
                            <v-icon v-else color="primary">{{item.icon}}</v-icon>
                            <v-list-item-content class="mx-2">
                                <v-list-item-title class="primary--text">{{ $t(item.title) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        
                        <v-divider class="primary mx-4" :key="item.title + '-divider'"></v-divider>
                    </template>
                     <div class="my-2 mt-4 text-caption primary--text  align-center"></div>
                  <div class="divider px-3"></div>
                  <!-- <div id="chooseLang">
                    <span class="primary--text">{{$t('currentLang')}} <small id="langPopover" class="changeLangButton">({{$t('changeLang')}})</small></span>
                    <v-list>
                      <v-list-item v-for="(locale, i) in locales" :key="i" @click="handleLangChange(locale)">
                        <v-list-item-title class="d-flex align-center" color="primary"><v-icon size="12" v-if="locale == selectedLocale" class="mr-2">mdi-check</v-icon> {{ $t(locale) }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </div> -->
                    <div class="d-flex flex-column align-center">
                        <v-menu class="custom-menu" offset-y bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="d-flex align-center justify-center primary--text" color="primary" text v-bind="attrs" v-on="on">
                                    <p class="ma-0 mr-1">{{$t('currentLang')}}</p>
                                    <p class="ma-0 mt-1 text-decoration-underline text-caption font-italic">(Changer)</p>
                                </div>
                            </template>
                            <v-list>
                                <v-list-item v-for="(locale, i) in locales" :key="i" @click="handleLangChange(locale)">
                                    <v-list-item-title class="d-flex align-center" color="primary">{{ $t(locale) }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <div class="newVersion">
                            <small>Version 1.1.1</small>
                        </div>
                    </div>  
                </v-list>
                <v-spacer></v-spacer>
                <div class="d-flex flex-column align-center account-btn">
                    <v-btn class="d-flex align-center align-self-center mt-auto" color="primary" small> <v-icon class="mr-2" size="16">mdi-account</v-icon> {{$t("headerAccountBtnLabel")}}</v-btn>
                </div>
            </div>
        </v-navigation-drawer>

        <RecallDialog />

        <v-dialog v-model="planTripDialog" width="500" persistent>
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              {{ $t("planTriplabel") }}
              <v-spacer></v-spacer>
              <v-btn icon class="" @click="displayPlanTripModal(false)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text class="pa-5 text-subtitle-1">
              {{$t("tripDescription")}}
            </v-card-text>
            <v-divider></v-divider>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import RecallDialog from "@/components/RecallDialog"
export default {
    props: {
        title: {
            type: String
        },
        description: {
            type: String
        },
        showBack: {
            type: Boolean,
            default: false
        },
        showHeader: {
            type: Boolean,
            default: true
        }
    },
    components: { RecallDialog },
    data(vm){
        return{
            dialog: false,
            drawer: false,
            items: [
                { title: 'headerHomeLabel', customIcon: true, icon: 'home', action: null, route: "menu" },
                { title: 'headerHistoryLabel', customIcon: true, icon: 'orders', action: null, route: "MyOrders" },
                { title: 'planTriplabel', customIcon: false, icon: 'mdi-car', action: "displayPlanTripModal", route: null },
                { title: 'headerScanPayLabel', customIcon: true, icon: 'scan', action:"goToScanAndPay", route: null },
                { title: 'headerRecalLabel', customIcon: true, icon: 'aide', action:"openCallMeDialog", route: null },
            ],
            planTripDialog : false,
            locales: vm.$i18n.availableLocales,
            showLangMenu: false
        }
    },
    mounted(){
        
        this.$emit("headerHeightChanged", this.$refs.header.offsetHeight)
        this.updateHeaderHeight(this.$refs.header.offsetHeight)
        //this.$emit("headerHeight", this.$refs.header.offsetHeight)
    },
    computed: {
        selectedLocale: {
            get(){
                return this.$store.getters["lang"]
            },
            set(value){
                this.$store.dispatch('updateLang', value)
            }
        }
    },
    methods: {
        ...mapActions({ 
            updateShowDialog: "recallDialog/updateShowDialog" 
        }),
        handleItemClick(item){
            if(item.route != null){
                this.$router.push({name: item.route})
            }
            if(item.action != null){
                this[item.action](true)
            }
        },
        openCallMeDialog(){
            this.drawer = false
            this.updateShowDialog(true)
        },
        displayPlanTripModal(showDialog){
          this.drawer = !showDialog
          this.planTripDialog = showDialog
        },
        goToScanAndPay(){
            window.open("https://wl-dsp-scan-and-pay.pubqlf.co.as8677.net/", '_blank').focus()  
        },
        handleLangChange(lang){
            this.selectedLocale = lang
            this.$i18n.locale = this.selectedLocale
        },
        clearCache(){
            localStorage.clear()
            window.location.reload()
        },
        restartOcularScene(){
            let topic = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.OCULAR_ACTION
            let message = {
                action: "restart"
            }
            this.$mqtt.publishMqtt(topic, JSON.stringify(message), () => {
                console.log("Ocular restarted")
            } )
        },
        ocularFullScreen(){
            let topic = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.OCULAR_ACTION
            let message = {
                action: "fullscreen"
            }
            this.$mqtt.publishMqtt(topic, JSON.stringify(message), () => {
                console.log("Ocular fullscreen mode")
            } )
        }
    }
}
</script>

<style scoped>
    .header{
        background: #46b8a6;
        z-index: 100;
    }
    .drawer-wrapper{
        width: 100%;
    }
    .drawer-header-icon{
        flex: 1 0;
    }
    .drawer-header-logo{
        flex: 6 0;
    }
    .header-actions{
        width: 100%;
    }
    .drawer-icon{
        font-size: 1.3rem;
    }
    .header-actions-wrapper{
        width: 100%;
        height: 100%;
    }
    .header-title{
        height: 48px;
        width: 100%;
    }
    .header-title >>> .v-app-bar-title__content{
        width: 100% !important;
    }
    .account-btn{
        position: absolute;
        bottom: 16px;
    }
    #chooseLang{
      padding-top: 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-top: auto;

    }
    .custom-menu{
        z-index: 1000;
    }
</style>