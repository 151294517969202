<template>
  <v-app>
    <v-overlay z-index="900" opacity="1" dark color="primary" :value="!isInPortrait">
      <div class="d-flex flex-column align-center">
        <v-icon size="64">mdi-phone-rotate-portrait</v-icon>
        <span class="my-2 text-button">{{$t("applicationNotAvailableInLandscape")}}</span>
      </div>
    </v-overlay>
    <Header v-if="$route.meta.showDefaultHeader != false" :title="currentPage.title" :description="currentPage.description" :showHeader="currentPage.showHeader != false" :showBack="currentPage.showBack == true"/>
    <StoreHeader v-else-if="$route.meta.headerToShow == 'StoreHeader'" :title="currentPage.title" :description="currentPage.description" :showHeader="currentPage.showHeader != false" :showBack="currentPage.showBack == true"/>
    
    <v-main :style="`height: calc(100 * ${vh}px - ${headerHeight}px)`">
        <router-view @pageCreated="currentPage = $event"/>
    </v-main>
    <BookSlotDialog />
    <PaymentValidationDialog />
    <IdentificationDialog v-if="customerToken == null" />
    <NewUpdateSnackBar />
    <Loader />
    <ChargingHomeDialog />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Header from "@/components/Header.vue"
import Loader from "@/components/Loader.vue"
import BookSlotDialog from "@/components/BookSlotDialog.vue"
import ChargingHomeDialog from "@/components/ChargingHomeDialog.vue"
import PaymentValidationDialog from "@/components/PaymentValidationDialog.vue"
import IdentificationDialog from "@/components/IdentificationDialog.vue"
import NewUpdateSnackBar from "@/components/NewUpdateSnackBar.vue"
import Cookies from 'js-cookie'
export default {
  name: 'App',
  components: {
    Header,
    Loader,
    BookSlotDialog,
    PaymentValidationDialog,
    IdentificationDialog,
    NewUpdateSnackBar,
    ChargingHomeDialog
  },
  data(){
    return {
      currentPage: {
        title: null,
        description: null,
        showBack: false,
        showIdentificationModal: false,
      },
      //isInPortrait: screen.orientation.type.toLowerCase().includes("portrait")
      isInPortrait: true
    }
  },
  created(){
  this.$i18n.locale = this.lang
    this.$mqtt.initMqttClient()
    let token = Cookies.get("IEC_TOKEN");
    if(!token){
        this.showIdentificationModal = true
    }else{
        this.updateCustomerFromCookies()
        this.showIdentificationModal = false
    }
    screen.orientation.addEventListener("change", e => {
      this.isInPortrait = e.currentTarget.type.toLowerCase().includes("portrait")
    })
  },
  methods:{
    ...mapActions({
      updateCustomerFromCookies: "customer/updateCustomerFromCookies",
      getOcularToken: "ocular/getToken",
      getCustomers: "ocular/getCustomers",
      updateNewUpdateSnackbar: "updateNewUpdateSnackbar"
    }),
    handleChatClass(newClass){
      let chat = window.document.getElementById("contact-chat");
      if(chat){
        switch(newClass){
          case "reduce":
            chat.classList.remove("chat-expand")
            chat.classList.add("chat-" + newClass)
            break;
          case "expand":
            chat.classList.remove("chat-reduce")
            chat.classList.add("chat-" + newClass)
            break;
        }
      }
    }
  },
  mounted(){
    this.updateVh(window.innerHeight * 0.01)
    document.addEventListener('newContentAvailable', (e) => {
        this.updateNewUpdateSnackbar(true);
    }, false);
    window.addEventListener('resize', () => { this.updateVh(window.innerHeight * 0.01)});
    window.addEventListener("message", e => { this.handleChatClass(e.data) }, false)
  },
  computed: {
    ...mapGetters({
      customerToken: "customer/customerToken",
      ocularToken: "ocular/token",
      lang: "lang",
    })
  },
  watch:{
    "$route"(){
      let chat = window.document.getElementById("contact-chat");
      if(chat){
        if(this.$route.name == "Menu"){
          chat.classList.remove("chat-hide")
          chat.classList.add("chat-show")
        }else{
          chat.classList.remove("chat-show")
          chat.classList.add("chat-hide")
        }
      }

    }
  }
};
</script>
<style>
  @import "./assets/css/custom-icons.css";
  @import "./assets/css/chat-worldline.css";
  .chat-reduce{
    height: 100px;
    width: 100px;
    bottom: 16px !important;
    right: 16px !important;
    z-index: 1;
  }
  .chat-expand{
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  .chat-hide{
    display: none !important;
  }
  .chat-show{
    display: block !important;
  }
</style>
