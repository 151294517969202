<template>
    <v-dialog v-model="showPaymentDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card class="d-flex flex-column payment-validation" :height="`${100 * vh}px`" color="white" flat>
            <v-toolbar dense dark class="payment-header" color="#005A8C">
                <v-btn icon dark @click="handleClose()">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title>My Bank</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card class="ma-4 pa-3 d-flex flex-column align-center payment-body" flat>   
                <div class="d-flex payment-body-icons-wrapper">        
                    <v-img class="drawer-header-logo" lazy-src="@/assets/img/wl-horizontal-logo.png" contain max-height="72px" max-width="72px" src="@/assets/img/wl-horizontal-logo.png"></v-img>
                    <v-spacer></v-spacer>
                    <v-img class="drawer-header-logo" lazy-src="@/assets/img/verified_visa.png" contain max-height="72px" max-width="72px" src="@/assets/img/verified_visa.png"></v-img>
                </div>
                <span class="text-h6">Secure Authentication</span>
                <span class="align-self-start text-caption">Please authenticate using your fingerprint.</span>
                <div class="align-self-start my-3 payment-info">
                    <span class="text-caption payment-info-title">Merchant</span>
                    <span class="text-caption payment-info-value">Worldline</span>
                    <span class="text-caption payment-info-title">Date</span>
                    <span class="text-caption payment-info-value">{{getCurrentDate()}}</span>
                    <span class="text-caption payment-info-title">Card Number</span>
                    <span class="text-caption payment-info-value">XXXX XXXX XXXX 0998</span>
                </div>
                <span class="align-self-start text-caption font-italic payment-info-explain">If you experience any problems, please contact our helpdesk on the back of your card.</span>
            </v-card>
            <v-spacer></v-spacer>
            <div v-if="timer != null" class="mb-10 d-flex flex-column align-center noselect">
                <v-progress-circular v-if="!check" indeterminate color="primary" size="64"></v-progress-circular>
                <v-icon size="64" class="animate__animated animate__zoomIn" v-else color="green">mdi-check</v-icon>
            </div>
            <v-icon class="mb-10 " v-touch="{ start: () => pressHandler(), end: () => pressHandler(true) }" size="64">mdi-fingerprint</v-icon>
        </v-card> 
    </v-dialog>
</template>

<script>
    import { mapActions } from "vuex"
    export default {
        data(){
            return {
                check: false,
                press: null,
                startDate: null,
                timer: null
            }
        },
        mounted(){
            this.updatePaymentDone(false)
            clearTimeout(this.timer)
        },
        methods:{
            ...mapActions({
                updateLoader: "UPDATE_LOADER",
                updatePaymentDone: "updatePaymentDone",
                updateLoaderText: "updateLoaderText"
            }),
            pressHandler(pressEnded = false){
                this.updatePaymentDone(false)
                if(!pressEnded){
                    this.startDate = new Date();
                    this.timer = setTimeout(() => {
                        this.check = true
                        this.updateLoaderText("loadingSlotBooking")
                        setTimeout(() => {
                            this.updatePaymentDone(true)
                            this.check = false
                            this.timer = null
                            this.showPaymentDialog = false
                        }, 1000);
                        
                    }, 1500);
                }else{
                    let endDate = new Date();
                    var diff = endDate - this.startDate;
                    if(diff < 1500){
                        clearTimeout(this.timer)
                        this.timer = null
                    }
                }
            },
            handleClose(){
                this.updateLoader(false)
                this.showPaymentDialog = false
            }
        },
        computed: {
            showPaymentDialog: {
                get(){
                    return this.$store.getters["showPaymentDialog"]
                },
                set(value){
                    this.$store.dispatch('updatePaymentDialogState', value)
                }
            }
        }
    }
</script>

<style scoped>

    * {
        -webkit-touch-callout:none !important;
        -webkit-user-select:none !important;
        -khtml-user-select:none !important;
        -moz-user-select:none !important;
        -ms-user-select:none !important;
        user-select:none !important;
        -webkit-tap-highlight-color:rgba(0,0,0,0) !important;
    }

    .payment-validation{
        position: fixed;
    }
    .payment-header{
        width: 100%;
        flex: 0;
    }
    .payment-body-icons-wrapper{
        width: 100%;
    }
    .payment-body{
        border: 1px solid;
    }
    .payment-info{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }
    .payment-info-explain{
        font-size: .55rem !important;
    }
</style>