const mqtt = require("mqtt");

const config = {
    host: "mqtt.labs.worldline-solutions.com",
    port: 8003,
    rejectUnauthorized: false,
    protocol: 'wss'
};

const credentials = {
    username: "rd",
    password: "@t0s@t0s"
}

const topics = {
    DEFAULT_TOPIC: "rd/chatbot/connectedKitchenBot/",
    OCULAR_ACTION: "occular/action"
}



String.prototype.format = function(params) {
    let string = this;
    let keys = Object.keys(params)
    for (let i in keys) {
        string = string.replace("{" + keys[i] + "}", params[keys[i]])
    }
    return string
}

let mqttReady = false;
let client;

function initMqttClient(callback = null){

    console.log("Init MQtt")

    if(!client || !client.connected){
        client = mqtt.connect(`${config.protocol}://${config.host}:${config.port}`, credentials);
        client.on('connect', () => {
            console.log("connected to mqtt server")
            mqttReady = true;
            if(callback != null){
                callback();
            }
        });

        client.stream.on('error', (err) => {
            if(err){
                console.error(err)
                console.error("Error while connection to MQTT broker");
                client.end();
            }
        });
        client.on("close", () => {
            client.reconnect();
        })
    }

}



function subscribeMqtt(topic, callback){
        if(client && client.connected && mqttReady){
            client.subscribe(topic, err => {
                if(!err){
                    console.log("Subscribed to topic " + topic);
                    callback();
                }else{
                    console.error("Error while subscribing to topic " + err);
                }
            });
        }else{
            initMqttClient( () => subscribeMqtt(topic, callback));
        }
}

function listenMqttMessage(topicToListen, callback){
    client.on('message', (topic, message)=> {
        if(topicToListen == topic){
            console.log("Message received on topic " + topic);
            callback(message);
        }
    });
}

function unsubscribe(topic, callback){
    client.unsubscribe(topic, callback);
}

function publishMqtt(topic, message, callback){
    if(client && client.connected && mqttReady){
        client.publish(topic, message, () => {
            console.log("Message published in topic " + topic);
            callback();
        });
    }else{
        initMqttClient(() => publishMqtt(topic, message, callback));
    }

}

function getClient(){
    return client;
}

function isMqttReady(){
    return mqttReady;
}

function endClient(){
    console.log("MQTT Client Ended")
    client.end(true);
}

const MQTT = {
    topics,
    subscribeMqtt,
    publishMqtt,
    listenMqttMessage,
    getClient,
    initMqttClient,
    endClient,
    unsubscribe,
    isMqttReady
}

export default MQTT;
