import { Http } from './http.init'
import { WS_URL } from "@/.env"

export class BaseService {
    static get entity(){
        throw new Error('Entity getter not defined')
    }

    static request(baseApi = WS_URL){
        return new Http(baseApi)
    }
}