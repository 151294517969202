import {OcularService} from "@/services/ocular.service"
export default {
    namespaced: true,
    state: {
        token: localStorage.getItem("IEC_OCULAR_TOKEN") || null
    },
    getters: {
        token: state => {
            return state.token;
        },
    },
    mutations: {
        updateToken(state, payload) {
            state.token = payload
            localStorage.setItem("IEC_OCULAR_TOKEN", state.token)
        }
    },
    actions: {
        getToken({commit}) {
            return new Promise(async (resolve, reject) => {
                console.log("Getting ocular token")
                try{
                    let {data, status} = await OcularService.getToken()
                    if(status == 200 ){
                        commit('updateToken', data.access_token);
                        resolve()
                    }else{
                        reject()
                    }
                }catch(e){
                    console.error(e)
                    reject()
                }
            })
        },
        getCustomers({commit}) {
            return new Promise(async (resolve, reject) => {
                console.log("Getting ocular token")
                try{
                    let {data, status} = await OcularService.getCustomers()
                    if(status == 200 ){
                        resolve(data)
                    }else{
                        reject()
                    }
                }catch(e){
                    console.error(e)
                    reject()
                }
            })
        }
    }
};