export default {
    namespaced: true,
    state: {
        showChargingDialog: false,
        startCharging: false,
        chargeMaxTime: null,
        chargeMaxVal: null
    },
    getters: {
        showChargingDialog: state => {
            return state.showChargingDialog;
        },
        startCharging: state => {
            return state.startCharging;
        },
        chargeMaxTime: state => {
            return state.chargeMaxTime;
        },
        chargeMaxVal: state => {
            return state.chargeMaxVal;
        }
    },
    mutations: {
        updateShowChargingDialog(state, payload){
          state.showChargingDialog = payload;
        },
        updateStartCharging(state, payload){
          state.startCharging = payload;
        },
        updateChargeMaxTime(state, payload){
          state.chargeMaxTime = payload;
        },
        updateChargeMaxVal(state, payload){
          state.chargeMaxVal = payload;
        }
    },
    actions: {
        updateShowChargingDialog({ commit }, payload){
          commit("updateShowChargingDialog", payload)
        },
        updateStartCharging({ commit }, payload){
          commit("updateStartCharging", payload)
        },
        updateChargeMaxTime({ commit }, payload){
          commit("updateChargeMaxTime", payload)
        },
        updateChargeMaxVal({ commit }, payload){
          commit("updateChargeMaxVal", payload)
        }
    }
};