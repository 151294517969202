import { WS_URL } from "@/.env"
import { BaseService } from "./base"


export class CustomerService extends BaseService {
    static get entity(){
        return 'customer'
    }

    static registerCustomerAnonym(firstName = null, lastName = null, email = null,token = null){
        
        const params = new URLSearchParams()
        if(token){
            params.append('anonymToken', token)
        }
        if(firstName){
            params.append('firstName', firstName)
        }
        if(lastName){
            params.append('lastName', lastName)
        }
        if(email){
            params.append('email', email)
        }

        return this.request(WS_URL, true).post(`${this.entity}/register/anonym`, params)
    }
}