import { BaseService } from "./base"

export class ContactService extends BaseService {
    static get entity(){
        return 'contact'
    }

    static get skillUuid(){
        return '5809d36d-52d0-4800-a01f-3d65a523311d'
    }
    static get skillName(){
        return 'infosPayNDrive'
    }
    static get campaign(){
        return 'PnDCallback'
    }
}