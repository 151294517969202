<template>
    <v-dialog v-model="showDialog" persistent>
        <v-card>
            <v-card-title class="text-h5">
                {{$t("identificationDialogTitle")}} 
                <v-spacer></v-spacer>
                <v-menu offset-y bottom left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="d-flex align-center" color="primary" text v-bind="attrs" v-on="on">
                            <span class="mr-1 font-weight-bold text-button">{{selectedLocale}}</span><v-icon x-small>mdi-menu-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(locale, i) in locales" :key="i" @click="handleLangChange(locale)">
                            <v-list-item-title class="d-flex align-center" color="primary"><v-icon size="12" v-if="locale == selectedLocale" class="mr-2">mdi-check</v-icon> {{ $t(locale) }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-title>

            <v-card-text>
                <div class="d-flex flex-column">
                    <v-text-field v-model="firstName" class="mx-2" :label="$t('identificationDialogFirstName')"></v-text-field>
                    <v-text-field v-model="lastName" class="mx-2" :label="$t('identificationDialogLastName')"></v-text-field>
                    <v-text-field v-model="email" type="email" class="mx-2" :label="$t('identificationDialogMail')"></v-text-field>
                </div>
            </v-card-text>
            <v-card-actions>
                <!-- <v-btn @click="showDialog = false" small>Annuler</v-btn> -->
                <v-spacer></v-spacer>
                <v-btn color="primary" :loading="loading" small @click="handleRegisterCustomer()">{{$t("identificationDialogValidateBtn")}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions } from "vuex"
    export default {
        data(vm){
            return{
                showSuccess: false,
                lastName: null,
                firstName: null,
                email: null,
                loading: false,
                showDialog: true,
                locales: vm.$i18n.availableLocales
            }
        },
        methods: {
            ...mapActions({
                registerCustomerAnonym: "customer/registerCustomerAnonym"
            }),
            async handleRegisterCustomer(){
                try{
                    let response  = await this.registerCustomerAnonym({
                        firstName: this.firstName,
                        lastName: this.lastName,
                        email: this.email
                    })
                    console.log(response)
                }catch(e){
                    console.error(e)
                }
            },
            handleLangChange(lang){
                this.selectedLocale = lang
                this.$i18n.locale = this.selectedLocale
            }
        },
        computed:{
                
            selectedLocale: {
                get(){
                    return this.$store.getters["lang"]
                },
                set(value){
                    this.$store.dispatch('updateLang', value)
                }
            }
        }
    }
</script>

<style scoped>

</style>