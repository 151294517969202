import { WS_URL } from "@/.env"
import { BaseService } from "./base"


export class ProductService extends BaseService {
    static get entity(){
        return 'catalog/product'
    }

    static getProductById(productId){
        return this.request(WS_URL, true).get(`${this.entity}/get/id/${productId}`)
    }
}