import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
          primary: "#46b8a6",
          secondary: "#fff",
          accent: "#000",
        },
      },
    },
  })